<template>
  <div
    class="content flex flex-col h-full w-screen justify-around overflow-x-hidden text-quarternary"
  >
    <router-link to="/" class="bg-zes"
      ><span class="material-icons p-4"> arrow_back </span></router-link
    >
    <div class="intro h-full my-12 flex flex-col justify-between items-center">
      <div class="hi flex justify-center items-center w-4/5 lg:w-2/5">
        <div
          class="desc md:items-start flex flex-col h-full w-full items-center justify-start"
        >
          <div
            class="introtext text-quarternary text-5xl lg:text-4xl font-bold"
          >
            <p class="header text-4xl lg:text-5xl leading-tight">
              Diverse projecten
            </p>
            <p class="text-base font-thin mt-2">
              Op deze pagina zijn meer projecten van mij te vinden.
            </p>
          </div>
        </div>
      </div>

      <div
        id="over"
        class="over w-screen h-full py-8 flex justify-center items-center flex-col rounded-lg bg-vijf mt-8"
      >
        <div class="desc w-4/5 lg:w-2/5">
          <div class="text-xl font-bold">
            Dutch Delicacies <br />
            <div class="text-lg font-thin">
              <br />
              Voor een data hacking project is Dutch Delicacies gemaakt. Dutch
              Delicacies is een website waarop je kunt zien hoeveel
              advertentie-inkomsten je hebt gegenereerd voor je favoriete
              artiesten. Het laat je zien hoeveel geld ze van je hebben
              verdiend, maar ook wat voor typisch nederlands eten ze met dit
              geld kunnen kopen. Mijn rol in dit project concepting, coding (API
              en JSONs) en grafisch ontwerp. <br /><br />
              <div class="text-base not-italic">
                <br />
                <!-- <a
                href="/explo.pdf"
                class="rounded-lg bg-quarternary text-primary opacity-80 h-full w-36 text-base not-italic text-center mb-8 p-1"
                >Onderzoeksdocument</a
              > -->
              </div>
            </div>
          </div>
        </div>
        <img
          class="sc py-8 flex justify-center items-center flex-col rounded-lg mt-8 lg:max-w-sm"
          src="../assets/ddsc.jpg"
        />
      </div>

      <div
        id="over"
        class="over w-screen h-full py-8 flex justify-center items-center flex-col rounded-lg mt-8"
      >
        <div class="desc w-4/5 lg:w-2/5">
          <div class="text-xl font-bold">
            Rebirth Control <br />
            <div class="text-lg font-thin">
              <br />
              Rebirth Control is een installatie die het gevoel van een
              wedergeboorte laat ervaren. Door verschillende zintuigen te
              prikkelen ontstaat er een out of body ervaring. De gebruiker neemt
              even afstand van zijn eigen lichaam. <br /><br />
              Als ontwerper van Rebirth Control lag mijn focus op de ervaring
              voor de gebruikers. Ik vind het heel belangrijk dat een
              installatie meeslepend is en een positieve emotie oproept bij de
              gebruiker. Het was mijn taak ervoor te zorgen dat Rebirth Control
              een gedenkwaardige en rijke ervaring was. <br /><br />
              <div class="text-base not-italic">
                <br />
                <!-- <a
                href="/explo.pdf"
                class="rounded-lg bg-quarternary text-primary opacity-80 h-full w-36 text-base not-italic text-center mb-8 p-1"
                >Onderzoeksdocument</a
              > -->
              </div>
            </div>
          </div>
        </div>
        <div
          class="videoframe w-screen lg:w-3/5 h-72 lg:h-96 flex flex-grow p-1/2"
        >
          <iframe
            class="w-full h-screen flex self-start"
            src="https://player.vimeo.com/video/275241234"
            style="width: 100%; height: 100%"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div
        id="over"
        class="over w-screen h-full py-8 flex justify-center items-center flex-col rounded-lg bg-vijf mt-8"
      >
        <div class="desc w-4/5 lg:w-2/5">
          <div class="text-xl font-bold">
            HCD Box <br />
            <div class="text-lg font-thin">
              <br />
              Voor een critical design project is de HCD box gemaakt.
              <br /><br />
              De Human-Centered Design Terminal is een verplichte tool voor
              ontwerpers om hun apps te laten certificeren en in de webshops te
              plaatsen. Wanneer een ontwerper een app heeft gemaakt die klaar is
              om in de app store te plaatsen, moet deze eerst worden
              gereguleerd.
              <br /><br />
              Zo kijkt de terminal onder meer welke knoppen belangrijk zijn voor
              de primaire actie, vergroot het formaat van kleine letters en
              vergroot het de lage contrasten. De interface van de app is
              aangepast aan deze behoeften van de doelgroep die de ontwerper in
              het begin betreedt.
              <br />
              <br />
              Dit beperkt de kracht van de ontwerper en maakt de applicatie
              gebruiksvriendelijker. Het resultaat is een helder ontwerp waaruit
              de bedoelingen duidelijker worden. Daarnaast wordt de gebruiker
              ook beperkt voor zijn gezondheid; in bijvoorbeeld de gebruikte
              tijd van de applicatie.
              <br /><br />
              <div class="text-base not-italic">
                <br />
                <!-- <a
                href="/explo.pdf"
                class="rounded-lg bg-quarternary text-primary opacity-80 h-full w-36 text-base not-italic text-center mb-8 p-1"
                >Onderzoeksdocument</a
              > -->
              </div>
            </div>
          </div>
        </div>
        <div
          class="videoframe w-screen lg:w-3/5 h-72 lg:h-96 flex flex-grow p-1/2 justify-center items-center"
        >
          <iframe
            width="560"
            height="210"
            src="https://www.youtube.com/embed/WEjUYO4PZ2w"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div
        id="over"
        class="over w-screen h-full py-8 flex justify-center items-center flex-col rounded-lg mt-8"
      >
        <div class="desc w-4/5 lg:w-2/5">
          <div class="text-xl font-bold">
            The Pyramid of Human Progression <br />
            <div class="text-lg font-thin">
              <br />
              The Pyramid of Human Progression is a videogame that takes a
              critical look at technological progression.
              <br /><br />
              Humanity is always looking for technological progress, a more
              comfortable life, a higher state where everything is better and
              more progressive. It's an eternal race to the top. Just like with
              Maslow's pyramid, we always try to find the next floor and think
              we can get better that way.
              <br /><b></b>
              But is this urge justified? Where does this urge for constant
              development come from? Does our life really improve with every
              invention and purchase, or is this just an assumption? Can we as
              humans ever reach the top of the pyramid?
              <br /><br />
              In The Great Pyramid of Human Progression, the player must view
              and worship all of humanity's technology in order to become
              enlightened and move up one level in the pyramid. But what awaits
              him/her at the top...
              <div class="text-base not-italic">
                <br />
                <!-- <a
                href="/explo.pdf"
                class="rounded-lg bg-quarternary text-primary opacity-80 h-full w-36 text-base not-italic text-center mb-8 p-1"
                >Onderzoeksdocument</a
              > -->
              </div>
            </div>
          </div>
        </div>
        <div
          class="videoframe w-screen lg:w-3/5 h-72 lg:h-96 flex flex-grow p-1/2 justify-center"
        >
          <img src="../assets/pyramid.png" />
        </div>
      </div>
      <div
        id="over"
        class="over w-screen h-full py-8 flex justify-center items-center flex-col rounded-lg mt-8"
      >
        <div class="desc w-4/5 flex-col">
          <div class="text-xl font-bold">
            <div class="text-base font-thin italic"></div>
          </div>
        </div>
        <!-- <div
          class="videoframe w-screen lg:w-3/5 h-72 lg:h-96 flex flex-grow p-1/2"
        >
          <iframe
            class="w-full h-screen flex self-start"
            src="https://player.vimeo.com/video/520710308"
            style="width: 100%; height: 100%"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div> -->
        <!-- <a
          href="https://framer.com/share/SOMA--8FvLmVNE3r0BWyvA5PdM/hNsTfrRGW"
          class="rounded-lg bg-quarternary text-primary opacity-80 h-full w-36 text-base text-center mb-8 lg:mt-8"
          >Ervaar op framer!</a
        > -->

        <div
          id="projects"
          class="projects h-full w-screen flex flex-col justify-between items-center mt-8"
        >
          <div
            class="show md:items-end flex flex-col h-full w-4/5 mt-8 items-start justify-center"
          >
            <p
              v-scroll-to="'#projects'"
              class="text-base font-semibold mt-2 flex lg:opacity-60 hover:opacity-100 cursor-pointer"
            >
              Andere projecten
              <span class="material-icons fill-current hover:animate-bounce">
                arrow_downward
              </span>
            </p>
          </div>
          <router-link
            to="/SOMA"
            v-scroll-reveal.reset.reset.reset
            class="project shadow-xl soma flex flex-col h-96 w-4/5 my-8 justify-start p-8 rounded-2xl bg-een cursor-pointer hover:scale-50 transition-transform ease-in"
          >
            <div class="introtext lg:w-2/5 text-quarternary text-2xl font-bold">
              <p class="header leading-tight">SOMA</p>
              <p class="text-base font-thin mt-2">
                Een smartphone app over lichaamsbewustzijn.
              </p>
              <br />
              <div class="tags flex flex-wrap w-full lg:w-4/5">
                <span
                  class="rounded-lg bg-quarternary text-een opacity-80 h-full w-24 text-base text-center m-1"
                  >UI Design</span
                >
                <span
                  class="rounded-lg bg-quarternary text-een opacity-80 h-full w-24 text-base text-center m-1"
                  >UX Design</span
                >
                <span
                  class="rounded-lg bg-quarternary text-een opacity-80 h-full w-24 text-base text-center m-1"
                  >Concept</span
                >
              </div>
            </div>
          </router-link>
          <router-link
            to="/Pom"
            v-scroll-reveal.reset.reset
            class="project shadow-xl pom flex flex-col h-96 w-4/5 lg:w-4/5 my-8 justify-start p-8 rounded-2xl bg-twee cursor-pointer"
          >
            <div class="introtext lg:w-2/5 text-quarternary text-2xl font-bold">
              <p class="header leading-tight">POM</p>
              <p class="text-base font-thin mt-2">
                Een praatmaatje voor kinderen met een taalachterstand.
              </p>
              <br />
              <div class="tags flex flex-wrap w-full lg:w-4/5">
                <span
                  class="rounded-lg bg-quarternary text-twee opacity-80 h-full w-24 text-base text-center m-1"
                  >UX Design</span
                >
                <span
                  class="rounded-lg bg-quarternary text-twee opacity-80 h-full w-24 text-base text-center m-1"
                  >Concept</span
                >
                <span
                  class="rounded-lg bg-quarternary text-twee opacity-80 h-full w-24 text-base text-center m-1"
                  >Animatie</span
                >
              </div>
            </div>
          </router-link>
          <router-link
            to="/Skatemap"
            v-scroll-reveal.reset.reset
            class="project shadow-xl skatemap flex flex-col h-96 w-4/5 lg:w-4/5 my-8 justify-start p-8 rounded-2xl bg-tertiary cursor-pointer"
          >
            <div class="introtext lg:w-2/5 text-quarternary text-2xl font-bold">
              <p class="header leading-tight">Skatemap</p>
              <p class="text-base font-thin mt-2">Een app voor skaters.</p>
              <br />
              <div class="tags flex flex-wrap w-full lg:w-4/5">
                <span
                  class="rounded-lg bg-quarternary text-vier opacity-80 h-full w-24 text-base text-center m-1"
                  >UI Design</span
                >
                <span
                  class="rounded-lg bg-quarternary text-vier opacity-80 h-full w-24 text-base text-center m-1"
                  >UX Design</span
                >
                <span
                  class="rounded-lg bg-quarternary text-vier opacity-80 h-full w-24 text-base text-center m-1"
                  >Concept</span
                >
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="copyright h-8 flex content-center items-center md:justify-center flex-col md:mt-0 text-quarternary wrap"
    >
      <div
        v-scroll-reveal.reset
        class="desc flex h-full md:h-full flex-row justify-center mx-6"
      >
        © Jisro, 2021
      </div>
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: "Divers",
};
</script>

<style scoped>
.introtext {
  line-height: 2rem;
}

.soma {
  background-image: url(../assets/soma.webp);
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

.pom {
  background-image: url(../assets/pom.webp);
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

.skatemap {
  background-image: url(../assets/skatemap.webp);
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

.project:hover {
  transform: scale(1.05);
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
</style>
